<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between">
          <div class="col-sm-8">
            <div class="heading">
              <h2>All Users</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-sm-4 text-sm-end mt-md-0 mt-2">
            <router-link :to="{ name: 'add_user' }" class="btn btn_primary" v-show="addPermission">Add New
              User</router-link>
            <a href="javascript:void(0)" class="btn btn_primary ml_24 " data-bs-toggle="modal"
              data-bs-target="#importUser" title="Import Users" @click="resetImports()" v-show="addPermission">Import
              User</a>
          </div>
        </div>

        <div class="date_toolbar_main all_users">
          <div class="d-flex align-items-center ">
            <div class="date-toolbar" style="box-shadow:none;">
              <div class="row align-items-center">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="d-lg-flex d-md-flex align-items-center justify-content-end">
                    <div class="bg-white date-toolbar">
                      <div class="row">
                        <div class="col-xs-12 col-md-12 align-self-center">
                          <div class="row">
                            <div class="col-xs-12 col-md-6">
                              <div class="date">
                                <div class="heading_5">From</div>
                                <div class="text_black font_size_14 d-flex align-items-center">
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                      viewBox="0 0 17.5 17.5">
                                      <path data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)" style="
                                                  fill: none;
                                                  stroke-linecap: round;
                                                  stroke-linejoin: round;
                                                  stroke-width: 1.5px;
                                                  " />
                                    </svg>
                                  </span>
                                  <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                                    :format="customFormatter" @selected="changeStartDate"
                                    :disabledDates="disabledFutureDates"></datepicker>
                                  <p class="min-date-value d-none">
                                    {{ listConfig.start_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                              <div class="date todate pl_6" style="border-right:0;">
                                <div class="heading_5">To</div>
                                <div class="
                                          text_black
                                          font_size_14
                                          d-flex
                                          align-items-center
                                          ">
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                      viewBox="0 0 17.5 17.5">
                                      <path data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)" style="
                                                      fill: none;
                                                      stroke-linecap: round;
                                                      stroke-linejoin: round;
                                                      stroke-width: 1.5px;
                                                      " />
                                    </svg>
                                  </span>
                                  <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                                    :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                                    :disabledDates="disabledDates"></datepicker>
                                  <p class="max-date-value d-none">
                                    {{ listConfig.end_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDates">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table_card all_user">
              <div class="row align-items-center dataTables_wrapper no-footer" id="datatable_wrapper">
                <div class="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <div class="dataTables_length" id="datatable_length">
                    <label>Show
                      <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                        @change="getProductsBysearch">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                        <option>500</option>
                      </select>
                      entries</label>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <!-- <div  class="dataTables_filter">
                        <div class="form-group">
                          <div class="input-group bsdatepicker">
                            <datepicker
                              placeholder="MM DD, YYYY"                        
                              v-model="listConfig.search_date"
                              :clear-button="clearButton"
                              class="w-100 newdatepker all_user_datepiker"
                              ref="dp2"
                              :format="'MMM dd, yyyy'"
                              :disabledDates="disabledFutureDates"
                              @selected="selectSearchDate"
                            ></datepicker>

                            <div class="input-group-prepend dpicker">
                              <button
                                class="btn btn-outline-primary"
                                type="button"
                                @click="showDatePicker"
                              >
                                <img
                                  src="../../assets/admin/images/calendar.svg"
                                  alt="img"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> -->
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group drop_text">
                    <!-- <label for="exampleInputEmail1" class="form-label">
                            Client</label
                          > -->
                    <v-select :reduce="(client_list) => client_list.id" :options="client_list" label="name"
                      placeholder="Client" name="Client" v-model="listConfig.search_clientId"
                      @input="getProductsBysearch" />
                    <small class="error text-danger" v-show="errors.has('addUserForm.Client')">
                      {{ errors.first("addUserForm.Client") }}
                    </small>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                  <div id="datatable_filter" class="dataTables_filter">
                    <label><input type="search" class="" placeholder="Search" aria-controls="datatable"
                        v-model="listConfig.search_string" v-on:keyup.enter="getProductsBysearch" />
                      <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getProductsBysearch"
                        class="search_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                          viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </a>
                      <a href="javascript:void(0)" v-if="listConfig.search_string"
                        @click="listConfig.search_string = null; getProductsBysearch()" class="crossicon">
                        X
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div
                  id="datatable_wrapper"
                  class="dataTables_wrapper no-footer">
                </div> -->
              <div class="table-responsive table_audit_log table_hgt">
                <table class="table table-bordered table dataTable no-footer">
                  <thead>
                    <tr>
                      <th class="sort">
                        <SortIcons :lable="'Customer'" :listConfig="listConfig" :sortBy="'company_name'"
                          @sorting="sorting($event)" />
                      </th>
                      <th class="sort">
                        <SortIcons :lable="'Name'" :listConfig="listConfig" :sortBy="'first_name'"
                          @sorting="sorting($event)" />
                      </th>
                      <th class="sort">
                        <SortIcons :lable="'Email'" :listConfig="listConfig" :sortBy="'email'"
                          @sorting="sorting($event)" />
                      </th>
                      <th class="sort">
                        <SortIcons :lable="'Role'" :listConfig="listConfig" :sortBy="'role_name'"
                          @sorting="sorting($event)" />
                      </th>
                      <th class="sort created_date_col">
                        <SortIcons :lable="'Created Date'" :listConfig="listConfig" :sortBy="'created_at'"
                          @sorting="sorting($event)" />
                      </th>
                      <th class="sort">
                        <SortIcons :lable="'Active'" :listConfig="listConfig" :sortBy="'active_status'"
                          @sorting="sorting($event)" />
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Main Loop -->
                    <tr v-for="(user, indx) in users_data" :key="indx">
                      <td>
                        {{ user.company_name }}
                      </td>
                      <td>
                        {{ user.first_name_camel }}
                        {{ user.last_name_camel }}
                      </td>
                      <td>
                        <p class="email_tag">
                          <a :href="'mailto:' + user.email">{{
                            user.email
                          }}</a>
                        </p>
                      </td>
                      <td>
                        <p class="role-permission" @click="showPermissions(user.role_uid)" data-bs-toggle="modal" data-bs-target="#viewpermission">{{ user.role_name | capitalize }}</p>
                      </td>
                      <td>
                        <p>{{ user.formated_date }}</p>
                      </td>

                      <td>
                        <p v-if="user.active_status">YES</p>
                        <p v-else>NO</p>
                      </td>

                      <td>
                        <div class="edit_btn">
                          <p>
                            <!-- :to="{ path:'/admin/edit_user/' + user.id }" -->
                            <router-link :to="{
                              name: 'edit_user',
                              params: { id: user.u_id },
                            }" class="" title="Edit" v-show="addPermission">
                              <img class="edit-icon" src="../../assets/admin/images/Edit_Icon.svg" alt="img" />
                            </router-link>

                            <a href="javascript:void(0)" v-show="deletePermission" @click="openModel(user.u_id)"
                              data-bs-toggle="modal" data-bs-target="#submit_form_and_download" title="Delete">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                style="margin-left: 10px; margin-top: -3px" viewBox="0 0 13.503 14.94">
                                <path data-name="Path 108"
                                  d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                                  transform="translate(-3 -2)" style="
                                    fill: none;
                                    stroke: #999292;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 2px;
                                  "></path>
                              </svg>
                            </a>
                            <router-link :to="{
                              name: 'user_summary_reports',
                              params: { id: user.u_id },
                            }" class="vierreport d-none" title="View Report">
                              <img src="../../assets/images/dashboard/View_Icon.svg" alt="View Report">
                            </router-link>

                            <router-link :to="{
                              name: 'alchemer_user_summary_reports',
                              params: { id: user.u_id },
                            }" class="vierreport" title="View Report">
                              <img src="../../assets/images/dashboard/View_Icon.svg" alt="View Report">
                            </router-link>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="data_length == 0">
                      <td colspan="7" class="text-center">
                        No Records found
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
              <p class="float-lg-start text-sm-center">{{ positionText }}</p>
              <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
            </div>
          </div>
        </div>
      </div>
    </main>

    <div class="modal user_box_up fade meet_our_team_modal" id="submit_form_and_download" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 pb_0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt_0">
            <div class="mb-3 form-group text-center">
              Why are you deleting this User?
            </div>
            <div class="mb-3 form-group maxheight">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">
                  Reason <span>*</span></label>
                <textarea height="100" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Reason of delete" name="delete_reason" v-model="delete_reason"
                  v-validate="'required|email'" v-on:keyup="countdown" maxlength="150" />
                <label class="text-end w-100">{{ remainingCount }} characters remaining</label>
                <small class="error text-danger">{{ delete_res }}
                </small>
              </div>

            </div>

            <div class="text-center mt_30">
              <button class="btn btn_primary mr_10" type="button" @click="deleteuser()">
                Yes
              </button>
              <button class="btn btn_outline" type="button" @click="closeModel()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade box_up import_modal" id="importUser" tabindex="-1" aria-labelledby="downloadLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_3">Import File</h3>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form method="post" @submit.prevent="importUsers" data-vv-scope="importUserForm"
            v-if="failed_import_length == 0" class="form-importfile">
            <div class="modal-body">
              <div class="row justify-content-around">
                <div class="col-md-10">
                  <div class="form-group">
                    <label for="" class="form-label">Upload File</label>
                    <div class="input-group align-items-center">
                      <input type="file" class="form-control" id="inputGroupFile02"
                        @change="onFileChange($event, 'importfile')" name="import_file"
                        v-validate="'required|ext:csv|size:2048'" ref="userUpload" />
                    </div>
                    <small class="error text-danger" v-show="errors.has('importUserForm.import_file')">
                      {{ errors.first("importUserForm.import_file") }}
                    </small>
                  </div>
                  <div>
                    Download sample file <a :href="sampleUrl" target="_blank">click here</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer mx-auto border-top-0 mb_50 justify-content-center">

              <button type="button" class="btn btn_primary_light mr_30 btn-close-import" data-bs-dismiss="modal"> Cancel
              </button>
              <button type="submit" class="btn btn_primary sml_download">Import</button>

            </div>
          </form>
          <div class="row justify-content-around" v-else>
            <div class="col-md-10">
              <small>The following({{ failed_import_length }}) records can not be imported.</small>
              <div class="table table-responsive for_img cust_scroll">
                <table class="table historyTable" width="100%" align="center">
                  <tr>
                    <th style="width:20%; font-size:14px">First Name</th>
                    <th style="width:20%; font-size:14px">Last Name</th>
                    <th style="width:35%; font-size:14px">Email</th>
                    <th style="width:25%; font-size:14px">Reason</th>
                  </tr>
                  <tr v-for="(imports, indx) in failed_imports" :key="indx">
                    <td style="width:15%; font-size:14px"> {{ imports.first_name }}</td>
                    <td style="width:15%; font-size:14px"> {{ imports.last_name }}</td>
                    <td style="width:35%; font-size:14px"> {{ imports.email }}</td>
                    <td style="width:25%; font-size:14px"> {{ imports.reason }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- View Permissions Modal -->
    <RolePermissionModal v-bind:roleName="roleName" v-bind:grantedPermissions="grantedPermissions"></RolePermissionModal>
    <!-- View Permissions Modal -->


  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from '../../mixin/commonFunction';
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import RolePermissionModal from "@/components/RolePermissionModal";

export default {
  name: "all_users",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    Datepicker,
    RolePermissionModal,
  },
  data() {
    return {
      users: [],
      users_data: [],
      selected: true,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "company_name",
        order: "ASC",
        search_string: null,
        start_date: null,
        end_date: null,
        search_clientId: null,
      },
      data_length: 0,
      isDisabled: '',
      addPermission: false,
      deletePermission: false,
      delete_id: 0,
      delete_res: "",
      delete_reason: "",
      limitmaxCount: 150,
      maxCount: 150,
      remainingCount: 150,
      hasError: false,
      client_list: [
      ],
      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      import_file: "",
      failed_import_length: 0,
      failed_imports: [],
      sampleUrl: process.env.VUE_APP_API_URL + "/Sample-File.csv",
      roleName: '',
      grantedPermissions: [],
      previousStartDate: null,
    };
  },
  computed: {
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getUsers();
    },
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.getClients();
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "company_name",
      order: "ASC",
      search_string: null,
      start_date: null,
      end_date: null,
      search_clientId: null,
    };
    this.getUsers();
    if (this.$route.params.successMsg) {
      this.$toast.success(this.$route.params.successMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    if (this.$route.params.errorMsg) {
      this.$toast.error(this.$route.params.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
  },
  beforeMount() {
    // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      // Store for check permission
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          let allPermission = value.user.roles.all_permission;
          if (allPermission.includes("users-edit")) {
            this.addPermission = true;
          }
          if (allPermission.includes("users-delete")) {
            this.deletePermission = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.tokenExpired();
    }
  },
  methods: {
    resetImports() {
      this.failed_imports = [];
      this.failed_import_length = 0;
      this.$refs.userUpload.value = null;
      this.$validator.reset();
    },
    importUsers() {
      var _this = this;
      var importErr = '';
      _this.$validator.validateAll("importUserForm").then((isValid) => {
        if (isValid) {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/user-import",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },

            data: { request_data: this.encodeAPIRequestWithImages({ import: this.import_file }) },
            // data: { import: this.import_file },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              var decodedJson = _this.decodeAPIResponse(data.data);
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (decodedJson.imports == 0) {
                // var importErr = '';
                  if(decodedJson.failed_import_counts == 1){
                    importErr = 'No user is imported.';
                  }else{
                    importErr = 'No user are imported.';
                  }
                _this.$toast.error(importErr, {
                  position: "top-right",
                  duration: 5000,
                });
              } else {
                _this.$toast.success(decodedJson.imports + ' user(s) has been imported.', {
                  position: "top-right",
                  duration: 5000,
                });
              }

              if (decodedJson.failed_import_counts > 0) {
                _this.failed_import_length = decodedJson.failed_import_counts;
                _this.failed_imports = decodedJson.failed_imports;

              } else {
                document.getElementsByClassName("btn-close-import")[0].click();
              }

            })
            .catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              document.getElementsByClassName("btn-close-import")[0].click();
              _this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
              if (response.status == 401) {
                _this.$router.push({ name: "login" });
              }
            });
        } else {
          console.log(_this.errors);
        }
      });
    },
    onFileChange(e, file_for) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImport(files[0], file_for);
    },
    createImport(file, file_for) {
      var reader = new FileReader();
      reader.onload = (e) => {
        if (file_for == "importfile") {
          this.import_file = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },

    resetDates() {
      this.listConfig.end_date = '';
      this.listConfig.start_date = '';
      this.isDisabled = true;
      this.getProductsBysearch();
    },
    getClients() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/clients",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);

          decodedJson.forEach((element) => {
            this.client_list.push({
              id: element.id,
              name: element.company_name,
            });
          });
        })
        .catch(({ response }) => {
          console.log(response);

        });
    },
    selectSearchDate() {
      this.$nextTick(() => {
        this.getProductsBysearch();
      });
    },
    showDatePicker() {
      this.$refs.dp2.showCalendar();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getUsers();
    },
    getProductsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getUsers();
    },
    getUsers() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/all-user-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.users_data = decodedJson.users;
            _this.data_length = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              _this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    // dataTableWithData() {
    //   $("#datatable").DataTable({
    //     bDestroy: true,
    //     aoColumnDefs: [
    //       {
    //         bSortable: false,
    //         aTargets: [-1],
    //       },
    //     ],
    //   });
    // },
    openModel(delete_user) {
      this.delete_reason = '';
      this.delete_res = '';
      this.delete_id = delete_user;
    },
    closeModel() {
      document.getElementsByClassName("btn-close")[0].click();
    },
    deleteuser() {
      var _this = this;
      let user_id = _this.delete_id;
      var delete_reason = _this.delete_reason;

      if (!delete_reason) {
        _this.delete_res = "Reason is required.";
        return false;
      } else {
        _this.delete_res = '';
      }
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      var requestData = { 'reason': delete_reason, 'id': user_id };
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/delete-user/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: this.encodeAPIRequestWithImages(requestData),
      };
      _this
        .axios(config)
        .then(({ data }) => {
          _this.closeModel();
          setTimeout(() => {
            _this.getUsers();
            // _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.success(data.message, {
              position: "top-right",
              duration: 5000,
            });
          }, 4000);

        })
        .catch(({ response }) => {
          _this.closeModel();
          if (response.status == 401) {
            _this.tokenExpired();
          } else {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          }
          setTimeout(() => {
            _this.getUsers();
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }, 4000);
        });
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.delete_reason.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log('Haserror:', this.hasError);
        return;
      }
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getProductsBysearch();

    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>
